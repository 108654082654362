<template>
  <v-card elevation="10">
    <v-card-title class="blue--text">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>

    <v-data-table :headers="headers" :items="inActiveSurveys" :search="search">
      <template v-slot:item.actions="{ item }">
        <v-menu app offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              slot="activator"
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item link :to="'/creator/' + item.surveyUniqueId">
              <v-list-item-title>
                <v-icon style="height: 16px; font-size: 16px" color="primary">
                  mdi-file-edit-outline
                </v-icon>

                <span
                  depressed
                  style="text-transform: unset"
                  class="primary--text mx-5"
                  >Edit Survey</span
                >
              </v-list-item-title>
            </v-list-item>

            <v-list-item link @click="goToAnalytics(item.surveyUniqueId)">
              <v-list-item-title
                ><v-icon style="height: 16px; font-size: 16px" color="primary"
                  >mdi-chart-line</v-icon
                >
                <span
                  depressed
                  style="text-transform: unset"
                  class="primary--text mx-5"
                  >Survey Analytics
                </span></v-list-item-title
              ></v-list-item
            >
            <v-list-item link>
              <v-list-item-title>
                <v-icon style="height: 16px; font-size: 16px" color="primary">
                  mdi-sync
                </v-icon>

                <v-dialog v-model="dialog" persistent max-width="400px">
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      style="text-transform: unset"
                      class="primary--text mx-5"
                      @click="takeId(item.surveyUniqueId)"
                    >
                      Re-use survey
                    </span>
                  </template>

                  <v-card color="rgba(255,255,255,1)">
                    <v-card-title class="text-h7 primary--text mb-3">
                      Re-use Survey
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col cols="12" lg="6">
                          <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="startDate"
                                label="Start date"
                                hint="MM/DD/YYYY format"
                                persistent-hint
                                outlined
                                dense
                                prepend-inner-icon="mdi-calendar"
                                v-bind="attrs"
                                @blur="startDate"
                                v-on="on"
                              />
                            </template>

                            <v-date-picker
                              v-model="startDate"
                              no-title
                              @input="menu1 = false"
                            />
                          </v-menu>
                        </v-col>

                        <!----- THE END DATE ------->
                        <v-col>
                          <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="endDate"
                                label="End date"
                                hint="MM/DD/YYYY format"
                                persistent-hint
                                outlined
                                dense
                                prepend-inner-icon="mdi-calendar"
                                v-bind="attrs"
                                @blur="endDate"
                                v-on="on"
                              />
                            </template>

                            <v-date-picker
                              v-model="endDate"
                              no-title
                              @input="menu2 = false"
                            />
                          </v-menu>
                        </v-col>
                      </v-row>

                      <v-radio-group v-model="access" row>
                        <v-radio label="Public" value="public" />

                        <v-radio label="Private" value="private" />
                      </v-radio-group>

                      <v-layout justify-end>
                        <v-btn
                          color="red "
                          class="mx-2 white--text"
                          style="text-transform: unset"
                          @click="dialog = false"
                        >
                          Cancel
                        </v-btn>

                        <v-btn
                          @click="copySurvey"
                          color="primary"
                          style="text-transform: unset"
                        >
                          Save
                        </v-btn>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-list-item-title>
            </v-list-item>

            <v-list-item link :to="'/export/' + item.surveyUniqueId">
              <v-list-item-title>
                <v-icon style="height: 16px; font-size: 16px" color="primary">
                  mdi-file-pdf-box
                </v-icon>
                <span style="text-transform: unset" class="mx-5 primary--text"
                  >Export as pdf
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="activateSurvey(item.surveyUniqueId)">
              <v-list-item-title>
                <v-icon style="height: 16px; font-size: 16px" color="success">
                  mdi-file-edit-outline
                </v-icon>

                <span style="text-transform: unset" class="mx-5 success--text"
                  >Activate survey
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="deleteSurvey(item.surveyUniqueId)">
              <v-list-item-title class="red--text"
                ><v-icon style="height: 16px; font-size: 16px" color="red"
                  >mdi-trash-can-outline</v-icon
                >
                <v-btn
                  depressed
                  style="text-transform: unset"
                  color="white"
                  class="red--text"
                  >Delete Survey
                </v-btn></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "InactiveSurveys",
  data: () => ({
    headers: [
      {
        text: "Survey Number",
        align: "start",
        sortable: false,
        value: "surveyNumber",
      },
      { text: "survey title", value: "surveyTitle" },
      { text: "Accessibility", value: "access" },
      { text: "Start date", value: "surveyStartDate" },
      { text: "End date", value: "surveyEndDate" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    access: null,
    search: "",
    startDate: new Date(Date.now()).toISOString().substr(0, 10),
    endDate: new Date(Date.now()).toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
    dialog: false,
  }),
  computed: {
    ...mapGetters({
      inActiveSurveys: "getInActiveSurveys",
    }),
  },
  methods: {
    activateSurvey(surveyUniqueId) {
      const status = "ACTIVE";
      const type = "survey";
      let data = {
        surveyStatus: status,
        surveyType: type,
        uniqueId: surveyUniqueId,
      };

      this.$store.dispatch("activateSurvey", data);
    },
    deleteSurvey(surveyUniqueId) {
      const status = "DELETED";
      const type = "quiz";
      let data = {
        surveyStatus: status,
        uniqueId: surveyUniqueId,
        surveyType: type,
      };
      this.$store.dispatch("removeSurvey", data);
    },

    copySurvey() {
      const data = {
        surveyUniqueId: this.surveyUniqueId,
        surveyStartdate: this.startDate,
        surveyEnddate: this.endDate,
        surveyAccess: this.access,
      }

      this.$store.dispatch("surveyCopying", data);

      this.dialog = false;
    },
    goToAnalytics(uid){

      this.$store.dispatch('fetchOverallSurveyAnswers', {surveyUniqueId: uid});
      this.$router.push('/analytics/' + uid);

    },
    takeId(surveyId) {
      this.surveyUniqueId = surveyId;
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchInactiveSurveys", {
      surveyStatus: "INACTIVE",
      surveyType: "survey",
    });
  },
};
</script>