<template>
  <v-card elevation="10">
    <v-card-title class="blue--text">


      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>

    <v-data-table :headers="headers" :items="surveysToRespond" :search="search">
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          slot="activator"
          color="primary"
          outlined
          v-bind="attrs"
          style="text-transform: unset"
          :to="'/response/' + item.surveyUniqueId"
        >
          Respond
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item link :to="'/export/' + item.surveyUniqueId">
          <v-list-item-title>
            <v-icon style="height: 16px; font-size: 16px" color="primary">
              mdi-file-pdf-box
            </v-icon>

            <span style="text-transform: unset" class="primary--text mx-5"
              >Export as pdf
            </span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item link @click="deleteSurvey(item.surveyUniqueId)">
          <v-list-item-title class="red--text"
            ><v-icon style="height: 16px; font-size: 16px" color="red"
              >mdi-trash-can-outline</v-icon
            >
            <span style="text-transform: unset" class="red--text mx-5"
              >Delete Survey
            </span></v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},

  data() {
    return {
      surveyTitle: null,
      dataCreated: false,
      dialog: false,
      tab: null,
      search: "",
      surveyUniqueId: "",
      menu1: false,
      menu2: false,
      access: null,
      categoryUniqueId: "",
      item: "",
      headers: [
        {
          text: "Survey Number",
          align: "start",
          sortable: false,
          value: "surveyNumber",
        },
        { text: "survey title", value: "surveyTitle" },
        { text: "Category", value: "surveyCategory" },
        // { text: "End date", value: "surveyEndDate" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    getDraftSurveys() {
      const type = "DRAFT";

      const data = {
        surveyStatus: type,
      };
      this.$store.dispatch("fetchDraftSurveys", data);
    },
    getActiveSurveys() {
      const type = "ACTIVE";
      this.$store.dispatch("fetchActiveSurveys", type);
    },
    getInactiveSurveys() {
      const type = "INACTIVE";
      this.$store.dispatch("fetchInactiveSurveys", type);
    },
    callToCreateSurvey() {
      this.createSurveyDialog = true;
    },

    takeId(surveyId) {
      this.surveyUniqueId = surveyId;
    },
    activateSurvey(surveyUniqueId) {
      const type = "ACTIVE";
      let data = {
        surveyStatus: type,
        uniqueId: surveyUniqueId,
      };

      this.$store.dispatch("activateSurvey", data);
    },

    deleteSurvey(surveyUniqueId) {
      const type = "DELETED";
      let data = {
        surveyStatus: type,
        uniqueId: surveyUniqueId,
      };
      this.$store.dispatch("deleteSurvey", data);
    },
  },
  async mounted() {
    await this.$store.dispatch("loadPrivateSurveyToRespond", {surveyType:'survey'});
  },
  computed: {
    ...mapGetters({
      categories: "getAllSurveyCategories",
      surveysToRespond: "getPrivateSurveyToRespond",
    }),
  },
  // watch: {
  //   async newDataCreated(newData) {
  //     if (newData) {
  //       await this.$store.dispatch("surveysList");
  //     } else {
  //     }
  //   },
  // },
};
</script>

<style scoped>
.scroll {
  overflow-y: auto;
}
.background {
  /* background-image: url("../../assets/images/background1.png"); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
.filter-blue {
  filter: invert(33%) sepia(100%) saturate(3814%) hue-rotate(192deg)
    brightness(93%) contrast(102%);
}
</style>

