<template>
  <v-card elevation="2">
    <v-card-title class="blue--text">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>

    <v-data-table :headers="headers" :items="draftSurvey" :search="search">
      <template v-slot:item.actions="{ item }">
        <v-menu app offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              slot="activator"
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item link :to="'/creator/' + item.surveyUniqueId">
              <v-list-item-title>
                <v-icon style="height: 16px; font-size: 16px" color="primary">
                  mdi-file-edit-outline
                </v-icon>

                <span style="text-transform: unset" class="mx-5 primary--text"
                  >Edit Survey
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="activateSurvey(item.surveyUniqueId)">
              <v-list-item-title>
                <v-icon style="height: 16px; font-size: 16px" color="success">
                  mdi-file-edit-outline
                </v-icon>

                <span style="text-transform: unset" class="mx-5 success--text"
                  >Activate survey
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item link :to="'/export/' + item.surveyUniqueId">
              <v-list-item-title>
                <v-icon style="height: 20px; font-size: 16px" color="primary">
                  mdi-file-pdf-box
                </v-icon>

                <span class="mx-5 primary--text" style="text-transform: unset"
                  >Export as pdf
                </span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item link @click="deleteSurvey(item.surveyUniqueId)">
              <v-list-item-title class="red--text"
                ><v-icon style="height: 16px; font-size: 16px" color="red"
                  >mdi-trash-can-outline</v-icon
                >
                <span style="text-transform: unset" class="red--text mx-5"
                  >Delete Survey
                </span></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DraftSurveys",
  data: () => ({
    headers: [
      {
        text: "Survey Number",
        align: "start",
        sortable: false,
        value: "surveyNumber",
      },
      { text: "survey title", value: "surveyTitle" },
      { text: "Accessibility", value: "access" },
      { text: "Start date", value: "surveyStartDate" },
      { text: "End date", value: "surveyEndDate" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    search: "",
  }),

  methods: {
    deleteSurvey(surveyUniqueId) {
      const status = "DELETED";
      const type = "quiz";
      let data = {
        surveyStatus: status,
        uniqueId: surveyUniqueId,
        surveyType: type,
      };
      this.$store.dispatch("removeSurvey", data);
    },
    activateSurvey(surveyUniqueId) {
      const status = "ACTIVE";
      const type = "survey";
      let data = {
        surveyStatus: status,
        surveyType: type,
        uniqueId: surveyUniqueId,
      };

      this.$store.dispatch("activateSurvey", data);
    },
  },
  computed: {
    ...mapGetters({
      draftSurvey: "getDraftSurveys",
    }),
  },
  async mounted() {
    await this.$store.dispatch("fetchDraftSurveys", {
      surveyStatus: "DRAFT",
      surveyType: "survey",
    });
  },
};
</script>

<style>
</style>